import { Box, Paper, Popover, Typography } from "@mui/material";
import React from "react";
import PerformanceTooltipItem from "./PerformanceTooltipItem";
import { StyledText } from "components/shared";
import HighlightItem, { HighlightItemProp } from "./HighlightItem";
import { ReactComponent as OfferIcon } from "../../../../assets/cai/svg/offer.svg";
import { ReactComponent as DiscountIcon } from "../../../../assets/cai/svg/discount.svg";
import { ReactComponent as BrandIcon } from "../../../../assets/cai/svg/brand.svg";
import { ReactComponent as BenifitIcon } from "../../../../assets/cai/svg/benifit.svg";
import { ReactComponent as NewBenifitIcon } from "../../../../assets/cai/svg/new_benifit.svg";
import { ReactComponent as SuitcaseIcon } from "../../../../assets/cai/svg/suitcase.svg";
import { ReactComponent as CustomerIcon } from "../../../../assets/cai/svg/customer.svg";
import {ReactComponent as NewCustomerIcon } from "../../../../assets/cai/svg/new_customer_centric.svg";
import { ReactComponent as BulbIcon } from "../../../../assets/cai/svg/bulb.svg";
import { ReactComponent as ProductIcon } from "../../../../assets/cai/svg/product.svg";
import { ReactComponent as CallToActionIcon } from "../../../../assets/cai/svg/cursorclick.svg";
import { ReactComponent as UniqueSellingPropositionIcon } from "../../../../assets/cai/svg/ranking.svg";
import { ReactComponent as ProblemSolutionIcon } from "../../../../assets/cai/svg/puzzle.svg";
import { ReactComponent as SocialProofIcon } from "../../../../assets/cai/svg/comment.svg";
import { ColorMapping } from "./performance.constant";
import { StyledFooter, StyledPaper, StyledPopover } from "./styled";
import { PerformanceScoreType } from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";

interface objectType {
  [key: string]: HighlightItemProp;
}

export const highlightConstObject: objectType = {
  offer: {
    text: "Offer Centric",
    bgColor: "#FDF2FA",
    textColor: "#EB59AC",
    icon: <OfferIcon />,
  },

  brand: {
    text: "Brand Focused ",
    bgColor: " #F1F6FF",
    textColor: "#2BB3F4",
    icon: <BrandIcon />,
  },

  benifit: {
    text: "Benefit Based",
    bgColor: " #F3EBFE",
    textColor: "#8832F5",
    icon: <BenifitIcon />,
  },
  customer: {
    text: "Customer Engaging",
    bgColor: "rgba(16, 171, 153, 0.07)",
    textColor: "#1ABFA1",
    icon: <CustomerIcon />,
  },
};

export const highlightConstObjectNew: objectType = {
  "Product Centric": {
    text: "Product Centric",
    bgColor: "#FEF7E3",
    textColor: "#C18C06",
    icon: <ProductIcon />,
  },

  "Brand Centric": {
    text: "Brand Centric",
    bgColor: "#FCE5F2",
    textColor: "#B4136E",
    icon: <SuitcaseIcon />,
  },

  "Call-to-Action": {
    text: "Call to Action",
    bgColor: "#F6F7F8",
    textColor: "#0350C3",
    icon: <CallToActionIcon />,
  },

  "Unique Selling Proposition": {
    text: "Unique Selling Point",
    bgColor: "#FEE7D5",
    textColor: "#9D5013",
    icon: <UniqueSellingPropositionIcon />,
  },

  "Customer Centric": {
    text: "Customer Centric",
    bgColor: "#EAF7F0",
    textColor: "#35915E",
    icon: <NewCustomerIcon />,
  },

  "Benefit Based": {
    text: "Benefit Based",
    bgColor: "#F6F7F8",
    textColor: "#5C6674",
    icon: <NewBenifitIcon />,
  },

  "Offer Based": {
    text: "Offer Based",
    bgColor: "#F2E5FD",
    textColor: "#6810B6",
    icon: <DiscountIcon />,
  },

  "Problem-Solution Structure": {
    text: "Problem Solution",
    bgColor: "#FDE6E5",
    textColor: "#B51511",
    icon: <ProblemSolutionIcon />,
  },

  "Social Proof": {
    text: "Social Proof",
    bgColor: "#FEEFE4",
    textColor: "#C05806",
    icon: <SocialProofIcon />,
  },
};

export default function PerformanceTooltip({
  open,
  anchorEl,
  infoItem,
  place,
}: {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  infoItem: PerformanceScoreType;
  place: "editor" | "variant";
}) {
  const id = open ? "performance-tooltip" : undefined;
  const calculateColorType = (value: number) => {
    if (value > 7) {
      return "high";
    } else if (value >= 4) {
      return "medium";
    }
    return "low";
  };
  function hasClassification(infoItem: { tags?: Record<string, { classification?: boolean }> }): boolean {
    return Object.keys(infoItem?.tags || {}).some(tag => {
      const tagData = infoItem?.tags && infoItem.tags[tag];
      return tagData && tagData.classification === true;
    });
  }


  const isKeyHighlights =
    infoItem?.offer_label ||
    infoItem?.brand_centric ||
    infoItem?.benefit_label ||
    infoItem?.customer_centric;


  const variantDetails = infoItem?.tags ? infoItem : infoItem?.data;

  const isNewPerformanceScoreType = infoItem?.data !== undefined || infoItem?.tags !== undefined;

  return (
    <StyledPopover
      place={place}
      open={open}
      id={id}
      anchorEl={anchorEl}
      sx={{
        pointerEvents: "none",
      }}
      anchorOrigin={{
        vertical: place === "variant" ? "bottom" : "top",
        horizontal: place === "variant" ? "left" : "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          boxShadow: "none",
          background: "transparent",
          marginTop: place === "variant" ? "0px" : "-1em",
          marginLeft: place === "variant" ? "-1.875em" : "0px",
        },
      }}
    >
      <StyledPaper
        sx={{
          background: "#FFFFFF",
          borderRadius: ".5em",
          width: "22em",
          marginTop: place === "variant" ? "0.9375em" : "0px",
          marginLeft: place === "variant" ? "0px" : "1.25em",
        }}
      // elevation={10}
      >
        <Box display="flex" flexDirection="column" padding="1em .9em">
          <PerformanceTooltipItem
            headSubtitle=">7 is considered Good"
            headTitle="Performance Score"
            midTitle={`${(infoItem?.performance_score ?? infoItem?.data?.performance_score)?.toFixed(1)}/10`}
            progressValue={isNewPerformanceScoreType && infoItem?.data?.performance_score !== undefined ? infoItem?.data?.performance_score * 10 : infoItem?.performance_score * 10}
            progressColor={
              ColorMapping[calculateColorType(isNewPerformanceScoreType && infoItem?.data?.performance_score !== undefined ? infoItem?.data.performance_score : infoItem?.performance_score)]
            }
          />
          {!isNewPerformanceScoreType && <PerformanceTooltipItem
            headSubtitle="Impacts CTR of your Ad"
            headTitle={
              place === "variant"
                ? "Overall Readability Score"
                : "Readability Score"
            }
            midTitle={`${infoItem?.readability_score?.toFixed(0)}/100`}
            progressValue={infoItem?.readability_score}
            isShowFooter
            progressColor="linear-gradient(90deg, #0869FB 6.86%, #0CF0FF 109.31%)"
          />}
          {
            (variantDetails && variantDetails.tags && hasClassification(variantDetails)) && (
              <>
                <StyledText
                  fontFamily="Lato"
                  lineHeight="15px"
                  fontWeight={600}
                  pb="1em"
                  fontSize="0.875em">
                  Key Highlights -
                </StyledText>
                <Box display="flex" flexWrap="wrap" gap=".7em">
                  {Object.keys(variantDetails.tags).map((key: string) => {
                    const tag = variantDetails.tags && variantDetails.tags[key];
                    if (tag && tag.classification) {
                      return <HighlightItem {...highlightConstObjectNew[key]} />
                    }
                  })}
                </Box>
              </>
            )}
          {isKeyHighlights && !isNewPerformanceScoreType && (
            <>
              <StyledText
                fontSize="0.75em"
                fontFamily="Lato"
                lineHeight="15px"
                fontWeight={600}
                pb=".5em"
              >
                Key Highlights -
              </StyledText>
              <Box display="flex" flexWrap="wrap" gap=".7em">
                {infoItem?.offer_label && (
                  <HighlightItem {...highlightConstObject["offer"]} />
                )}
                {infoItem?.brand_centric && (
                  <HighlightItem {...highlightConstObject["brand"]} />
                )}

                {infoItem?.benefit_label && (
                  <HighlightItem {...highlightConstObject["benifit"]} />
                )}
                {infoItem?.customer_centric && (
                  <HighlightItem {...highlightConstObject["customer"]} />
                )}
              </Box>
            </>
          )}
        </Box>
        {infoItem?.insights && (
          <StyledFooter>
            <BulbIcon />
            <StyledText
              fontSize="10px"
              fontWeight={500}
              lineHeight="14px"
              color="#F99F35"
              pr=".2em"
              pl=".5em"
            >
              Ad Variant needs
            </StyledText>

            <StyledText
              fontSize="10px"
              fontWeight={500}
              lineHeight="14px"
              color="#E84B47"
              pr=".2em"
            >
              attention
            </StyledText>

            <StyledText
              fontSize="10px"
              fontWeight={500}
              lineHeight="14px"
              color="#F99F35"
            >
              before publishing !
            </StyledText>
          </StyledFooter>
        )}
      </StyledPaper>
    </StyledPopover>
  );
}
