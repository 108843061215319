import { useContext, useEffect, useState,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useReactFlow } from "react-flow-renderer";
import TAIDropdown from "../components/TAIDropdown";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {
  getBrandAIGroups,
  getTargetingAICountries,
  getTargetingAIRegions,
  setAiInitiationData,
  resetCalibrationData,
  setAiInitiationValue,
  setAdAccountDetails,
} from "../../../store/actions/targetingAI";
import { ReactComponent as CaretDown } from "../../../assets/tai/caretDown.svg";
import CommonLoader from "../../../assets/Gif/CommonLoader.gif";
import { mixPanelAdEvent } from "../../../utils/mixpanel";
import { ReactComponent as EditTai } from "../ReactFlowComponents/Assets/svgs/EditTai.svg";
import { ReactComponent as Continue } from "../ReactFlowComponents/Assets/svgs/continue.svg";
import taiDropdownCheckmark from "../../../assets/tai/taiDropdownCheckmark.svg";
import "./ai-initiation-node.scss";
import { getSearchParams } from "utils/commonFunctions";
import { TaiContext } from "../TaiContextProvider";
import {
  getGroupAdAccounts,
  getGroups,
  setActiveBrand,
  setGroups,
} from "store/actions/adaccount";
import { getBrandDetails } from "store/actions/accountsManager";
import { getAccountDetails } from "store/actions/common";
import { resetGSCalibrationData } from "store/actions/gsTargetingAI";
import { useSearchParams } from "react-router-dom-v5-compat";
import TAIMultiSelectDropdown from "../components/TAIMultiSelectDropdown";
import SearchComponent from "Pages/SmbDashboard/common/SearchComponent";
import { Box, ListSubheader } from "@mui/material";
import { debounce } from "lodash";

const removeIntro = () => {
  const isPlaygroundIntroPresent = document.getElementById("playground-intro");
  const isTaiStartPresent = document.getElementById("tai-start");
  if (isPlaygroundIntroPresent && isTaiStartPresent) {
    document.getElementById("playground-intro").remove();
    document.getElementById("tai-start").remove();
  }
};

const AIInitiationNode = ({ data }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const ai_group_id = searchParams.get("ai_group_id");
  const {
    checkStepAndComplete,
    selectedPlatform,
    setSelectedPlatform,
    setShowSettingsIconHeader,
  } = useContext(TaiContext);

  const adaccount = useSelector((state) => state.adaccount);
  const { selectedBrand, groups } = adaccount;
  const currentPage = useSelector((state) => state.taiCommon.currentPage);
  const { formData, apiData } = useSelector((state) => state.aiInitiation);
  const {
    dropdown,
    aiGroupOptions,
    countriesOptions,
    regionOptions,
    selectedAiGroup,
    selectedCountry: selectedCountries,
    selectedRegion,
    disabled,
  } = formData;

  const { adAccount } = apiData;
  const aiInitiationData = useSelector((state) => state.aiInitiation.apiData);
  const [brandWebsite, setBrandWebsite] = useState(
    aiInitiationData?.website || "Select Brand Website"
  );
  const [isRegionListLoading,setIsRegionListLoading] = useState(true);
  const [brandName, setBrandName] = useState(aiInitiationData?.name || "");
  const [selectedAdAccount, setSelectedAdAccount] = useState(adAccount || null);
  const platformsOptions = ["Google Search", "Meta"];
  const fbPlatformId = "1c59a6e2-843b-4634-92a0-6fa93ce6c761";
  const googlePlatformId = "1c59a6e2-843b-4632-92a0-6fa93ce6c761";
  const platformsOptionsIds = [googlePlatformId, fbPlatformId];
  const [platformIdsArray, setPlatformIdsArray] = useState([]);
  const [adAccounts, setAdAccounts] = useState([]);
  const [selectAllAction,setSelectAllAction] = useState(false);
  const [filteredAdAccountsOptionsList,setFilteredAdAccountsOptionsList] = useState(adAccounts);
  const common = useSelector((state) => state.common);
  const { selectedAgency } = common;
  const [adAccountLoading, setAdAccountLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const debouncedSetFilterText = debounce((filterText)=>{
    setFilteredAdAccountsOptionsList(
      adAccounts?.filter((adAccount)=>adAccount?.name?.toLowerCase().includes(filterText.toLowerCase()) || adAccount?.id?.includes(filterText))
    );
  },300);

  useEffect(()=>{
    debouncedSetFilterText(filterText);
  },[filterText,adAccounts]);
 
  useEffect(() => {
    const platformIds = selectedAiGroup?.adAccounts.map(
      (element) => element.platformId
    );
    setPlatformIdsArray(platformIds);
    setSelectedPlatform(aiInitiationData?.platform?.name || "Select Platform");
  }, [selectedAiGroup]);

  useEffect(() => {
    location &&
      location.state &&
      location.state.aiGroup &&
      dispatch(
        setAiInitiationValue({
          value: location.state.aiGroup,
          key: "selectedAiGroup",
        })
      );
  }, []);

  const handleOptions = (optionsList, keyname) =>
    optionsList?.reduce((prev, curr) => {
      prev[curr[keyname]] = curr;
      return prev;
    }, {});
 
  useEffect(() => {
    const brandId = getSearchParams("brand_id");
    dispatch(
      getBrandAIGroups(
        {
          brandId,
        },
        (response, error) => {
          if (!error && response.data?.length > 0) {
            dispatch(
              setAiInitiationValue({
                value: handleOptions(response.data, "id"),
                key: "aiGroupOptions",
              })
            );
          } else {
            dispatch(
              setAiInitiationValue({
                value: {},
                key: "aiGroupOptions",
              })
            );
          }
        }
      )
    );
    if (brandId && platformsOptions.includes(selectedPlatform)) {
      dispatch(
        getTargetingAICountries((response, error) => {
          if (!error && response.data?.message?.countries?.length > 0) {
            dispatch(
              setAiInitiationValue({
                value: handleOptions(response.data.message.countries, "code"),
                key: "countriesOptions",
              })
            );
          }
        })
      );
    }
    //Setting brand name based on selected AI group and selected Platform earlier it was based on google - for the brand name to be displayed and editable
    if (selectedAiGroup && selectedPlatform) {
      setBrandName(selectedBrand.name);
    }
  }, [selectedBrand, selectedPlatform, dispatch,selectedAiGroup]);
   
  useEffect(()=>{
    if (selectedAiGroup && selectedAiGroup.id) {
      setAdAccountLoading(true);
      dispatch(
        getGroupAdAccounts(
          {
            groupId: selectedAiGroup.id,
            platformId:
              selectedPlatform === platformsOptions[0]
                ? googlePlatformId
                : fbPlatformId,
          },
          (adaccRes, adaccError) => {
            if (!adaccError || adaccRes.data.length > 0) {
              const accounts = adaccRes.data.map((adcc) => ({
                name: adcc.adAccountId,
                id: adcc.adAccountId,
                ...adcc,
              }));
              setAdAccounts(accounts);
              setFilteredAdAccountsOptionsList(accounts);
              //Commenting this out because this will be shown in fb and as well google
              //earlier we not displayed for facebook so sent first index ad account to
              //the selectedAdAccount
              // if (selectedPlatform === platformsOptions[1]) {
              //   setSelectedAdAccount(accounts[0]);
              // }
            } else {
              setAdAccounts([]);
              setFilteredAdAccountsOptionsList([]);
            }
            setAdAccountLoading(false);
            if(!disabled){
              setSelectedAdAccount(null);
            }
           
          }
        )
      );
    }
  },[selectedPlatform, dispatch,selectedAiGroup]);
  useEffect(() => {
    if (selectedBrand)
      dispatch(
        getGroups({ brandId: selectedBrand?.id }, (grpResponse, grpError) => {
          // needed for action debugger
          if (!grpError) {
            const groups = [...grpResponse.data];
            dispatch(setGroups(groups));
          } else {
          }
        })
      );
  }, [selectedBrand]);

  useEffect(() => {
    if (ai_group_id && groups.find((group) => group.id === ai_group_id)) {
      dispatch(
        setAiInitiationValue({
          key: "selectedAiGroup",
          value: groups.find((group) => group.id === ai_group_id),
        })
      );
    }
  }, [ai_group_id, groups]);
  useEffect(() => {
    if (selectedPlatform === platformsOptions[0]) setAdAccountLoading(true);
  }, [selectedPlatform]);

  useEffect(() => {
    setIsRegionListLoading(false);
    if (selectedCountries.length > 0 &&
      selectedPlatform === platformsOptions[1] && !selectedRegion.length) {
      setSelectAllAction(false);
      setIsRegionListLoading(true);
      dispatch(
        setAiInitiationValue({
          value: null,
          key: "regionOptions",
        })
      );
      dispatch(
        getTargetingAIRegions((selectedCountries || []).map((country)=>country.code), (response, error) => {
          setIsRegionListLoading(false);
          if (!error && response?.success) {
            dispatch(
              setAiInitiationValue({
                value: handleOptions(response.data.message.regions, "key"),
                key: "regionOptions",
              })
            );
          }
        })
      );
    }
  }, [selectedCountries,selectedPlatform,dispatch]);

  const storeData = () => {
    const aiInitiationData = {
      name: brandName,
      website: brandWebsite.startsWith("https://")
        ? brandWebsite
        : `https://${brandWebsite}`,
      aiGroup: selectedAiGroup,
      platform: { name: selectedPlatform },
      adAccount: selectedAdAccount,
      country: selectedCountries,
      region: selectedRegion.length > 0 ? selectedRegion : [],
    };
    dispatch(setAiInitiationData(aiInitiationData));
    dispatch(resetCalibrationData());
    mixPanelAdEvent(
      `TAI${
        selectedPlatform === platformsOptions[0] ? "-GSearch" : ""
      } Inititation Step Done`,
      {
        module_name:
          selectedPlatform === platformsOptions[0]
            ? "GSearch Playground"
            : "Facebook Playground",
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        agency_id: selectedAgency?.id,
        brand_type: selectedBrand?.brandType,
        agency_name: selectedAgency?.name,
        input_brand_name: brandName,
        brand_website: brandWebsite.startsWith("https://")
          ? brandWebsite
          : `https://${brandWebsite}`,
        ai_group_name: selectedAiGroup?.name,
        ai_group_id: selectedAiGroup?.id,
        country_name: selectedCountries,
        adaccount_name: selectedAdAccount?.name,
        adaccount_id: selectedAdAccount?.id,
        region_names:
          selectedPlatform === platformsOptions[1]
            ? selectedRegion?.reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
              }, [])
            : undefined,
      }
    );
    dispatch(
      setAiInitiationValue({
        value: true,
        key: "disabled",
      })
    );
  };

  useEffect(() => {
    if (!selectedBrand) {
      const brandId = getSearchParams("brand_id");
      dispatch(
        getBrandDetails({ brandId }, (response, error) => {
          if (!error) {
            dispatch(setActiveBrand(response.data));
          }
        })
      );
    }
  }, []);
  

  return (
    <>
      <div className="p-2 collapsed-header">
        <div className="collapsed-heading">AI Initiation</div>
        <div
          className={`header-icon ${
            disabled || currentPage > data.index ? "" : "d-none"
          }`}
          onClick={
            currentPage > data.index && !disabled
              ? () => {
                  dispatch(
                    setAiInitiationValue({
                      value: true,
                      key: "disabled",
                    })
                  );
                }
              : data.onEditClick
          }
        >
          {currentPage > data.index && !disabled ? (
            <Continue />
          ) : (
            <EditTai
              style={{ cursor: "pointer" }}
              onClick={() =>
                dispatch(
                  setAiInitiationValue({
                    value: false,
                    key: "disabled",
                  })
                )
              }
            />
          )}
        </div>
      </div>
      <hr className="collapsed-header-hr" />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(resetGSCalibrationData());
          setShowSettingsIconHeader(false);
          checkStepAndComplete([1], data.index, () => {
            removeIntro();
            storeData();
            data.onNext();
          });
          if (selectedPlatform === platformsOptions[0]) {
            dispatch(
              getAccountDetails(
                { account_id: selectedAdAccount.id },
                (response, error) => {
                  dispatch(setAdAccountDetails(response.data));
                }
              )
            );
          }
        }}
        id="text-updater"
        className="justify-center p-2 align-middle text-updater w-100"
      >
         {/* This button enables the AI Groups options based upon dropdown whenever the ai group options are available it gets enables and sets dropdown value to 1 */}
        <button
          className={`input-box w-100 mt-4 inline-flex items-center border-0 outline-none cursor-pointer btn-aigroup`}
          id="aiGroup"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            if (aiGroupOptions) {
              dispatch(
                setAiInitiationValue({
                  value: dropdown === 1 ? -1 : 1,
                  key: "dropdown",
                })
              );
            }
          }}
          style={{ backgroundColor: "#F2F2F2" }}
          disabled={disabled}
        >
          <span className="btn-aigroup">
            <div className="d-flex btn-aigroup">
              <div className="duration-200 d-flex align-items-center justify-content-between w-100 btn-aigroup">
                <div className="mr-2 grow btn-aigroup text-truncate">
                  {selectedAiGroup?.name || "Select AI Group"}
                </div>
                <div
                  className="inline-flex ml-auto grow-0 btn-aigroup"
                  role="presentation"
                >
                  {aiGroupOptions ? (
                    <CaretDown className="btn-aigroup" />
                  ) : (
                    <img
                      src={CommonLoader}
                      height={20}
                      width={20}
                      alt="loader"
                      className="btn-aigroup"
                    />
                  )}
                </div>
              </div>
            </div>
          </span>
        </button>
        
        {/* when dropdown sets to 1 Customised TAI dropdown appears and only one option can be selected */}

        {dropdown === 1 && (
          <TAIDropdown
            optionsList={Object.fromEntries(
              Object.entries(aiGroupOptions).sort(([, a], [, b]) =>
                a?.name?.trim().localeCompare(b?.name?.trim())
              )
            )}
            dropdown={dropdown}
            selectedAiGroup={selectedAiGroup}
            selectedCountry={selectedCountries}
            selectedRegion={selectedRegion}
            classes="rounded border-b-2 w-100 mt-1 p-2 text-sm position-absolute"
            loading={false}
            setSelectedAdAccount={
              selectedPlatform === platformsOptions[0]
                ? setSelectedAdAccount
                : null
            }
          />
        )}
        
        {/*Platform selection From Material UI select component*/}
        <Select
          className={`input-box w-100 mt-2 inline-flex items-center outline-none cursor-pointer btn-aigroup`}
          sx={{
            "& fieldset": {
              border: "none",
              padding: "0px",
            },
            "& .MuiSelect-select": {
              padding: "0px",
            },
            "& div[role=button]": { p: 0 },
            "& svg": { color: "inherit" },
            background: "rgb(242, 242, 242)",
          }}
          defaultValue=""
          displayEmpty
          renderValue={() => selectedPlatform}
          MenuProps={{
            PaperProps: {
              sx: {
                "& li": {
                  background: "none !important",
                },
                width: "260px !important",
                background: "rgb(250, 250, 250)",
                zIndex: "50",
                border: "1px solid rgb(240, 240, 240)",
                boxShadow: "rgb(0 0 0 / 5%) 0px 4px 14px",
                borderRadius: "8px",
              },
            },
          }}
          disabled={disabled || platformIdsArray === undefined}
        >
          {platformsOptions.map((platform, index) => (
            <MenuItem
              disabled={!platformIdsArray?.includes(platformsOptionsIds[index])}
              key={platform}
              value={platform}
              onClick={() => {
                setSelectedPlatform(platform);
                setBrandWebsite(
                  selectedBrand?.websiteMetaDetails.length === 1
                    ? selectedBrand?.websiteMetaDetails[0].website
                    : "Select Brand Website"
                );
                history.push(
                  `/targeting-ai-playground-beta/${platform === 'Meta' ? 'facebook' : platform
                    .toLowerCase()
                    .replaceAll(/\s/g, "")}${window.location.search}`
                );
              }}
              sx={{
                fontSize: "0.7rem",
              }}
            >
              {platform}
              {selectedPlatform === platform && (
                <img
                  src={taiDropdownCheckmark}
                  alt="checkmark"
                  style={{
                    position: "absolute",
                    right: "7%",
                  }}
                />
              )}
            </MenuItem>
          ))}
        </Select>
        {/*Brand website selection From Material UI select component*/}
        <Select
          className={`input-box w-100 mt-2 inline-flex items-center outline-none cursor-pointer btn-aigroup`}
          sx={{
            "& fieldset": {
              border: "none",
              padding: "0px",
            },
            "& .MuiSelect-select": {
              padding: "0px",
            },
            "& div[role=button]": { p: 0 },
            "& svg": { color: "inherit" },
            background: "rgb(242, 242, 242)",
          }}
          defaultValue=""
          displayEmpty
          renderValue={() => brandWebsite}
          MenuProps={{
            PaperProps: {
              sx: {
                "& li": {
                  background: "none !important",
                },
                width: "260px !important",
                background: "rgb(250, 250, 250)",
                zIndex: "50",
                border: "1px solid rgb(240, 240, 240)",
                boxShadow: "rgb(0 0 0 / 5%) 0px 4px 14px",
                borderRadius: "8px",
              },
            },
          }}
          required={brandWebsite === "Select Brand Website"}
          disabled={disabled || selectedPlatform === "Select Platform"}
        >
          {selectedBrand?.websiteMetaDetails?.map((website) => (
            <MenuItem
              key={website.website}
              value={website.website}
              onClick={() => {
                setBrandWebsite(website.website);
              }}
              sx={{
                fontSize: "0.7rem",
              }}
            >
              {website.website}
              {brandWebsite === website.website && (
                <img
                  src={taiDropdownCheckmark}
                  alt="checkmark"
                  style={{
                    position: "absolute",
                    right: "7%",
                  }}
                />
              )}
            </MenuItem>
          ))}
        </Select>
        <input
          placeholder="Enter Brand Name"
          className="mt-2 input-box brand w-100 "
          id="brandName"
          name="brandName"
          value={brandName}
          required
          onChange={function (e) {
            setBrandName(e.target.value);
          }}
          style={{
            outline: "none",
            border: "none",
            background: "#F2F2F2",
            borderRadius: "8px",
          }}
          readOnly={selectedPlatform === platformsOptions[0]}
          disabled={disabled || selectedPlatform === "Select Platform"}
        />
        {/*Select Country Selection Dropdown component*/}
        <button
          className={`input-box w-100 mt-2 inline-flex items-center border-0 outline-none cursor-pointer btn-aigroup`}
          id="country"
          onClick={(e) => {
            e.preventDefault();
            if (countriesOptions) {
              dispatch(
                setAiInitiationValue({
                  value: dropdown === 2 ? -1 : 2,
                  key: "dropdown",
                })
              );
            }
          }}
          style={{ backgroundColor: "#F2F2F2" }}
          disabled={
            disabled ||
            selectedPlatform === "Select Platform" ||
            selectedPlatform === platformsOptions[0]
          }
        >
          <span className="btn-aigroup">
            <div className="d-flex btn-aigroup">
              <div className="duration-200 d-flex align-items-center justify-content-between w-100 btn-aigroup">
                <div className="mr-2 grow btn-aigroup text-truncate">
                  {selectedCountries?.map((country)=>country.name).join(",") || "Select Country"}
                </div>
                <div
                  className="inline-flex ml-auto grow-0 btn-aigroup"
                  role="presentation"
                >
                  {countriesOptions ||
                  selectedPlatform === "Select Platform" ? (
                    <CaretDown className="btn-aigroup" />
                  ) : (
                    <img
                      src={CommonLoader}
                      height={20}
                      width={20}
                      alt="loader"
                      className="btn-aigroup"
                    />
                  )}
                </div>
              </div>
            </div>
          </span>
        </button>
        {dropdown === 2 && (
          <TAIMultiSelectDropdown
            optionsList={countriesOptions}
            dropdown={dropdown}
            selectedAiGroup={selectedAiGroup}
            selectedCountry={selectedCountries}
            selectedRegion={selectedRegion}
            classes="rounded border-b-2 w-100 mt-1 p-2 text-sm position-absolute"
            loading={false}
            selectAll={selectAllAction}
            setSelectAll={setSelectAllAction}
          />
        )}
        {/* Ad Accounts List shown here in this component */}
          <Select
            className={`input-box w-100 mt-2 inline-flex items-center outline-none cursor-pointer btn-aigroup`}
            sx={{
              "& fieldset": {
                border: "none",
              },
              "& .MuiSelect-select": {
                padding: "0px",
              },
              "& div[role=button]": { p: 0 },
              "& svg": { color: "inherit" },
              background: "rgb(242, 242, 242)",
            }}
            defaultValue=""
            displayEmpty
            renderValue={() =>
              selectedAdAccount
                ? selectedAdAccount.name || selectedAdAccount.id
                : "Select Ad account"
            }
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: "12.5rem",
                  height: "100%",
                  overflow: "auto",
                  "& li": {
                    background: "none !important",
                  },
                  width: "260px !important",
                  background: "rgb(250, 250, 250)",
                  zIndex: "50",
                  border: "1px solid rgb(240, 240, 240)",
                  boxShadow: "rgb(0 0 0 / 5%) 0px 4px 14px",
                  borderRadius: "8px",
                },
              },
            }}
            disabled={
              disabled ||
              adAccountLoading ||
              selectedPlatform === "Select Platform"
            }
            IconComponent={() =>
              adAccountLoading ? (
                <img
                  src={CommonLoader}
                  height={20}
                  width={20}
                  alt="loader"
                  className="btn-aigroup"
                />
              ) : (
                <CaretDown className="btn-aigroup" />
              )
            }
          >
          <div>
          <>
          <SearchComponent
             searchTerm={filterText}
             setSearchTerm={setFilterText}
             placeholder={"Search Term"}
             width="16rem"
             autoFocus={true}
             custom={true}
           />
        </>  
          {filteredAdAccountsOptionsList?.length > 0 ? (
            filteredAdAccountsOptionsList.map((adAccount) => {
              return (
                <MenuItem
                  key={adAccount.id + adAccount.platformId}
                  value={adAccount.name || adAccount.id}
                  onClick={() => {
                    setSelectedAdAccount(adAccount);
                  }}
                  sx={{
                    fontSize: "0.7rem",
                  }}
                >
                   <Box sx={{
                      display:'flex',
                      flexDirection:'column'
                   }}>
                      <span>{adAccount.name || adAccount.id}</span>
                      <span>{adAccount.id}</span>
                   </Box>
                 
                  {selectedAdAccount &&
                    selectedAdAccount.id === adAccount.id &&
                    selectedAdAccount.platformId === adAccount.platformId && (
                      <img
                        src={taiDropdownCheckmark}
                        alt="checkmark"
                        style={{
                          position: "absolute",
                          right: "7%",
                        }}
                      />
                    )}
                </MenuItem>
              );
            })
          ) : (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
              No Data Found
            </div>
          )}
          </div>
          </Select>
        
        {selectedPlatform === platformsOptions[1] && (
          <button
            className={`input-box w-100 mt-2 inline-flex items-center border-0 outline-none btn-aigroup`}
            id="region"
            disabled={!regionOptions || disabled}
            onClick={(e) => {
              e.preventDefault();
              if (selectedCountries.length>0) {
                dispatch(
                  setAiInitiationValue({
                    value: dropdown === 3 ? -1 : 3,
                    key: "dropdown",
                  })
                );
              }
            }}
            style={{
              backgroundColor: "#F2F2F2",
            }}
          >
            <span className="btn-aigroup">
              <div className="d-flex btn-aigroup">
                <div
                  className={`duration-200 d-flex align-items-center justify-content-between w-100 ${
                    selectedCountries.length>0 ? "" : "opacity-50"
                  } btn-aigroup`}
                >
                  <div
                    className="mr-2 grow btn-aigroup text-truncate"
                  >
                    {selectedRegion.length > 0
                      ? selectedRegion.map((region) => region.name).join(", ")
                      : "Select Region (Optional)"}
                  </div>
                  <div
                    className="inline-flex ml-auto grow-0 btn-aigroup"
                    role="presentation"
                  >
                    {(isRegionListLoading) ? (
                      <img
                        src={CommonLoader}
                        height={20}
                        width={20}
                        alt="loader"
                        className="btn-aigroup"
                      />
                    ) : (
                      <CaretDown className="btn-aigroup" />
                    )}
                  </div>
                </div>
              </div>
            </span>
          </button>
        )}
        {selectedPlatform === platformsOptions[1] &&
          selectedCountries.length>0 &&
          dropdown === 3 && (
            <TAIMultiSelectDropdown
              optionsList={regionOptions}
              dropdown={dropdown}
              selectedAiGroup={selectedAiGroup}
              selectedCountry={selectedCountries}
              selectedRegion={selectedRegion}
              classes="rounded border-b-2 w-100 mt-1 p-2 text-sm position-absolute"
              loading={false}
              selectAll={selectAllAction}
              setSelectAll={setSelectAllAction}
            />
          )}
        <div
          className={`flex justify-content-end ${disabled ? "d-none" : ""}`}
          style={{ padding: "0.8rem 0" }}
        >
          <button
            type="submit"
            className="run-btn btn primary font-weight-bold"
            style={{
              position:'sticky',
              bottom:0,
            }}
            disabled={
              !brandName ||
              !brandWebsite ||
              !selectedAiGroup ||
              !selectedPlatform ||
              !selectedAdAccount ||
              (selectedPlatform === platformsOptions[1] && !selectedCountries.length) ||
              (selectedPlatform === platformsOptions[0] && !selectedAdAccount)
            }
          >
            Run
          </button>
        </div>
      </form>
    </>
  );
};
export default AIInitiationNode;
