import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCreateSpec } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  getFacebookImageUrl,
  getFacebookImageUrlForAssetFeedSpec,
  getFacebookVideoUrl,
} from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { AdAccountState } from "store/types/adaccount";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import {
  setAdAdditionalInfo,
  setAdAdditionalInfoReset,
} from "store/actions/CreativeAI_Revamp/HomeScreen/homeScreen.actions";
import orderBy from "lodash/orderBy";

export const useVariantListing = () => {
  const dispatch = useDispatch();

  const [imageAvatarLoading, setImageAvatarLoading] = useState(false);
  const [videoDataFbLoading, setVideoDataFbLoading] = useState(false);
  const [isFetchingImageForAssetFeedSpec, setIsFetchingImageForAssetFeedSpec] =
    useState(false);

  // redux states
  const { adsList } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );

  const {
    variantList,
    selectedAd,
    addUpdateVariantLoading,
    filteredVariantList,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const { adAccountsSelection, adAdditionalInfo } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  useEffect(() => {
    if (selectedAd?.id) {
      // to fetch facebook page avatar and name

      const createSpec = selectedAd?.adcreatives?.data[0];
      if (
        createSpec?.object_story_spec?.page_id &&
        adAdditionalInfo?.[selectedAd.id]?.avatarUrl === undefined
      ) {
        setImageAvatarLoading(true);
        dispatch(
          getFacebookImageUrl({
            adId: createSpec?.object_story_spec?.page_id, /// actually fb page id
            callback: (response: any, status: boolean) => {
              dispatch(
                setAdAdditionalInfo({
                  adId: selectedAd.id,
                  adInfo: {
                    ...response,
                  },
                })
              );
              setImageAvatarLoading(false);
            },
          })
        );
      }

      // to fetch facebook original ad video url
      if (
        (createSpec?.object_story_spec?.video_data?.video_id ||
          createSpec?.asset_feed_spec?.videos?.[0]?.video_id) &&
        adAdditionalInfo?.[selectedAd.id]?.videoUrl === undefined
      ) {
        setVideoDataFbLoading(true);
        dispatch(
          getFacebookVideoUrl({
            adId:
              createSpec?.object_story_spec?.video_data?.video_id ??
              createSpec?.asset_feed_spec?.videos?.[0]?.video_id, /// actually video id
            callback: (response: any, status: boolean) => {
              dispatch(
                setAdAdditionalInfo({
                  adId: selectedAd.id,
                  adInfo: {
                    videoUrl: response?.source,
                  },
                })
              );
              setVideoDataFbLoading(false);
            },
          })
        );
      }

      // to fetch image in case of not available in creative spec
      if (
        createSpec?.asset_feed_spec?.images?.[0]?.hash &&
        adAdditionalInfo?.[selectedAd.id]?.image_url === undefined &&
        createSpec?.image_url === undefined
      ) {
        setIsFetchingImageForAssetFeedSpec(true);
        dispatch(
          getFacebookImageUrlForAssetFeedSpec({
            adAccountId: adAccountsSelection?.selectedAdAccountId ?? "",
            imageHash: createSpec?.asset_feed_spec?.images?.[0]?.hash,
            callback: (response: any, status: boolean) => {
              dispatch(
                setAdAdditionalInfo({
                  adId: selectedAd.id,
                  adInfo: {
                    image_url: response?.[0]?.permalink_url,
                  },
                })
              );

              setIsFetchingImageForAssetFeedSpec(false);
            },
          })
        );
      } else {
        dispatch(
          setAdAdditionalInfo({
            adId: selectedAd.id,
            adInfo: {
              image_url: createSpec?.image_url,
            },
          })
        );
      }
    }
  }, [selectedAd?.id]);

  return {
    selectedAd,
    variantList,
    filteredVariantList,
    addUpdateVariantLoading,
    adsList,
    imageAvatarLoading,
    videoDataFbLoading,
    isFetchingImageForAssetFeedSpec,
  };
};
