import { Box, Divider } from "@mui/material";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CloseDrawer } from "../../../../../assets/svg/closeDrawer.svg";
import {
  resetState,
  setRecommendationLoading,
  updateRecommendationMetadata,
  isShowSaveVariantPromptAction,
  showUpdateAISuccessMsgAction,
  setRecommendationError,
  setTimeStamp,
  addUpdateVariantLoadingState,
  updateRecommendationSelectionState,
  generateAdPreview,
} from "../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  getRecommendationMetadataTemplateAPICall,
  setRecommendationTimer,
} from "../../../../../store/actions/CreativeAI/Facebook/SelectCohort/selectCohort";
import { initialState } from "../../../../../store/types/auth";
import {
  CreateVariantsState,
  GENERATE_VARIANT_METADATA_STATE_VARIANT,
  ImageLayerType,
  Image_Recommendation,
  VideoLayerType,
  VideoRecommendation,
} from "../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { ConfirmDialog, AlertDialog, Button, Loader } from "../../../../shared";
import { CustomizedSnackBars } from "../../../../shared/AlertSnackBar/AlertSnackBar";

import {
  generateConfigurePayload,
  getFormValues,
} from "../../utils/form.utils";
import Configure from "./Configure/Configure";
import ConfigureFooter from "./EditorFooter/ConfigureFooter";
import RecommendationFooter from "./EditorFooter/RecommendationFooter";
import EditorHeader from "./EditorHeader/EditorHeader";
import { useEditor } from "./hooks/useEditor";
import Recommendations from "./Recommendations/Recommendations";
import EditorGif from "../../../../../assets/Gif/EditorGif.gif";
import {
  StyledEditorGifContainer,
  StyledEditorGif,
  StyledGreatBestTxt,
  StyledAISettingTxt,
  StyledAIProvideTxt,
  StyledDrawer,
  StyledEditorBody,
  StyledMediaLoader,
} from "./styled";
import { ItemType } from "./Editor.types";
import { Toolbar, ToolbarItems } from "./toolbar";
import { nanoid } from "nanoid";
import {
  GetMediaStudioProcessingReduxType,
  GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
  GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO,
  InitialMediaStudioType,
  MEDIA_STUDIO_IMAGE_PROCESSING_LOADER,
  MEDIA_STUDIO_VIDEO_PROCESSING_LOADER,
  RESTORE_INITIAL_MEDIA,
} from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { useVGSPollingHook } from "./hooks/useVgsPollingHook";
import {
  convertVideoFrameData,
  imageMediaProcessingPayloadMapping,
  videoMediaProcessingPayloadMapping,
} from "./MediaStudio/shared/mediaCommon.utils";
import {
  getMediaStudioProcessing,
  postMediaStudioProcessing,
  setFrameThumbnailData,
} from "store/actions/CreativeAI/Facebook/CreateVariant/mediaStudio";
import { getPresignedUrl } from "store/actions/CreativeAI/Facebook/MediaStudioAdmin/mediaStudioAdmin";
import { AdAccountState } from "store/types/adaccount";
import { NavigationInitialState } from "store/types/Navigation/navigation.types";
import { APIGenerationType } from "../../shared/SharedTypes";
import { filterVariantData } from "../../utils/filterLevers.utils";
import {
  SET_AD_ADSET_FIELD_LIST,
  SET_ELEMENT_CREATIVE_AI,
} from "store/types/CreativeAI/Facebook/Main/Main";
import {
  checkAdType,
  mapAdAdSetToElement,
} from "components/CreativeAI_Revamp/Facebook/utils/common.utils_old";
import { useCaiFbMixPanel } from "components/CreativeAI_Revamp/Facebook/HomeScreen/hooks/useCaiFbMixPanel";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
export default function Editor() {
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [selectedTool, updateSelectedTool] = useState<ItemType | null>(
    ToolbarItems[0]
  );

  const {
    reqImageIds,
    reqVideoIds,
    isImageMediaStudioProcessing,
    isImageMediaStudioProcessingFailure,
    isVideoMediaStudioProcessing,
    isVideoMediaStudioProcessingFailure,
    frameVGSReqIdLoading,
    frameVGSReqIdFailure,
  } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );

  const { getVGSProcessingResponse } = useVGSPollingHook();

  const {
    variantList,
    // loadingRecommendation,
    extraFields: { showEditor, selectedVariantIndex },
    addUpdateVariantLoading,
    isConfiguringMetaData,
    isShowSaveVariantPrompt,
    executionIdVariant,
    recommendationSelectionState: { isChanged, isConfigureChanged },
    showUpdateAISuccessMsg,
    generateRecommendationError,
    configureMetaData,
    createSpec,
    recommendationMetaData,
    isGeneratingMetaData,
    selectedAd,
  } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const { BUSINESS_UNIT_BY_ID_DATA } = useSelector((state: any) => ({
    BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
  }));
  const {
    textFields,
    onAdSettingChange,
    handleSubmit,
    onGenerate,
    control,
    getValues,
    setValue,
    reset,
    watch,
    isUpdateAISettingChecked,
    onUpdateAISettingCheckClick,
    errors,
    setError,
    clearErrors,
    isGeneratingRecommendation,
    setIsGeneratingRecommendation,
  } = useEditor();
  const [isConfiguring, setIsConfiguring] = useState(false);
  const [updateInitialRecommendation, setUpdateInitialRecommendation] =
    useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { CREATIVE_ELEMENT_AI, LEVERS } = useSelector((state: any) => ({
    CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi.data,
    LEVERS: state.MainReducer.defaultLevers,
  }));
  const timerRef = useRef<any>(null);
  const { recommendationTimer } = useSelector(
    (state: { selectCohortReducer: any }) => state.selectCohortReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;
  const { adAccountsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );

  const { socket } = useSelector(
    (state: { navigationReducer: NavigationInitialState }) =>
      state.navigationReducer
  );

  const { getMediaStudioData } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );
  const { COHORT_DATA } = useSelector(
    (state: { selectCohortReducer: any; createVariantReducer: any }) => ({
      COHORT_DATA: state?.selectCohortReducer?.cohortData,
    })
  );
  //
  const {
    mixPanelCreativeStudioSeeMore,
    mixPanelCreativeStudioAdSettingButtonClick,
    mixPanelCreativeStudioRenderUpdate,
    mixPanelCreativeStudioOriginalAdDetailsClicked,
    mixPanelCreativeStudioConfigureClicked,
    mixPanelCreativeStudioConfigureGenerateClicked,
  } = useCaiFbMixPanel();
  // common function and variables

  // lever filteration
  const data: APIGenerationType[] = useMemo(
    () =>
      filterVariantData(LEVERS, [
        "user",
        "ad",
        "adset",
        "campaign",
        "ad_video",
        "ad_image",
      ]),
    [LEVERS]
  );

  const recommendationRef = useRef();

  const onCrossClick = () => {
    if (isChanged || isConfigureChanged) setShowDiscardModal(true);
    else {
      onDiscard();
    }
  };
  const onDiscard = () => {
    setShowDiscardModal(false);
    const localVariantList = variantList.filter(
      (element: any) => element.id !== null
    );
    let initState: any = {
      variantList: localVariantList,
      loadingRecommendation: true,
      isConfiguringMetaData: false,
      extraFields: {
        isVariantEditing: undefined,
        selectedVariantIndex: undefined,
        showEditor: undefined,
      },
      generateRecommendationError: false,
      configureMetaData: undefined,
      generateRecommendationTimestamp: undefined,
      recommendationMetaData: {},
      recommendationSelectionState: {
        primary_text: "",
        headline: "",
        description: "",
        adPreviewLink: "",
        variantName: "",
        isChanged: false,
        isConfigureChanged: false,
      },
    };
    dispatch(resetState(initState));

    dispatch({
      type: RESTORE_INITIAL_MEDIA,
      payload: {
        reqImageIds: [],
        frameVGSReqId: [],
        reqVideoIds: [],
        isImageMediaStudioProcessing: false,
        isVideoMediaStudioProcessing: false,
        isVideoMediaStudioProcessingFailure: false,
        isImageMediaStudioProcessingFailure: false,
        imageVgsTimer: null,
        videoVgsTimer: null,
        videoFrameRelatedData: {},
        videoVGSFrame: {},
        frameVGSReqIdLoading: false,
        frameVGSReqIdFailure: false,
      },
    });
  };

  // socket event listen new code ------>

  useEffect(() => {
    setIsGeneratingRecommendation(true);

    if (selectedVariantIndex !== undefined) {
      const createSpecAPI = selectedAd?.adcreatives?.data[0];
      const adType = checkAdType(createSpecAPI);
      let creativeElementData = { ...CREATIVE_ELEMENT_AI };
      // if (!creativeElementData?.localSetup) {
      let newLeverData: any;
      if (getMediaStudioData?.isData) {
        if (adType === "link_data") {
          newLeverData = data.filter(
            (item) => item.generation_type !== "video"
          );
        } else {
          newLeverData = data.filter(
            (item) => item.generation_type !== "image"
          );
        }
      } else {
        newLeverData = data.filter(
          (item) =>
            item.generation_type !== "video" && item.generation_type !== "image"
        );
      }
      const { newCreativeElementAI, adAdSetFieldList } = mapAdAdSetToElement({
        createSpec: createSpecAPI,
        targeting: COHORT_DATA?.facebookData?.targeting,
        creativeElementAI: CREATIVE_ELEMENT_AI,
        levers: newLeverData,
        getMediaStudioData,
      });
      dispatch({
        type: SET_ELEMENT_CREATIVE_AI,
        payload: {
          data: { ...newCreativeElementAI, localSetup: true },
        },
      });
      dispatch({
        type: SET_AD_ADSET_FIELD_LIST,
        payload: adAdSetFieldList,
      });
      creativeElementData = newCreativeElementAI;
      // }

      if (variantList[selectedVariantIndex].id === null) {
        if (BUSINESS_UNIT_BY_ID_DATA) {
          const payloadData = generateConfigurePayload(
            {
              ...getFormValues(creativeElementData, true),
              businessUnit: BUSINESS_UNIT_BY_ID_DATA?.businessUnit,
              businessUnitDetail: BUSINESS_UNIT_BY_ID_DATA?.businessUnitDetail,
            },
            LEVERS,
            "generate",
            adType,
            selectedBrand.id,
            selectedAd?.id,
            adAccountsSelection?.selectedAdAccountId
          );

          if (adType === "link_data") {
            delete payloadData.input_data?.video;
          } else {
            delete payloadData.input_data?.image;
          }
          /// debugging data
          try {
            if (Object.keys(payloadData.input_data).length === 0) {
              console.log("debugging data ----->", {
                businessUnit: BUSINESS_UNIT_BY_ID_DATA?.businessUnit,
                businessUnitDetail:
                  BUSINESS_UNIT_BY_ID_DATA?.businessUnitDetail,
                creativeElementData,
                LEVERS,
                adType,
                formaData: getFormValues(creativeElementData, true),
              });
            }
          } catch (error) {
            console.log("Unable to console log debug data");
          }
          dispatch({
            type: "GENERATE_VARIANT_METADATA",
            payload: {
              user: auth.user,
              data: payloadData,
            },
          });
        }
      } else {
        dispatch(addUpdateVariantLoadingState(false));
        dispatch(setRecommendationLoading(false));
        dispatch(
          updateRecommendationMetadata({
            data: cloneDeep(
              variantList[selectedVariantIndex].payload?.recommendationMetaData
            ),
            configureMetaData:
              variantList[selectedVariantIndex].payload?.configureMetaData,
          })
        );
        setIsGeneratingRecommendation(false);
      }
    }
  }, []);

  useEffect(() => {
    if (socket) {
      // for recommendation
      socket.on("CaiFBRecommendations", function (data) {
        console.log("here we got the recommendation data", data);

        if (data?.status === "failed") {
          dispatch(setRecommendationError(true));
        } else {
          const formattedData = formateRecommendationData(data.data);
          if (variantList[selectedVariantIndex].id) {
            setUpdateInitialRecommendation(true);
          }

          dispatch(
            updateRecommendationMetadata({
              data: {
                // @ts-ignore: Unreachable code error
                ...recommendationRef.current,
                ...formattedData,
                isEditing: undefined,
                isDeleting: undefined,
                isVideoImageEdit: undefined,
              },
              configureMetaData,
            })
          );
          if (formattedData?.video) {
            dispatch({
              type: RESTORE_INITIAL_MEDIA,
              payload: {
                videoVGSFrame: {},
              },
            });
          }
        }
        setIsGeneratingRecommendation(false);
      });
    } else {
      // here socket fallback case will come
    }
    return () => {
      if (socket) {
        socket.off("CaiFBRecommendations");
      }
    };
  }, [socket]);

  // kept here for socket fallback case

  // const getRecommendationMetDataTemplate = useCallback(
  //   ({
  //     executionId,
  //     generateRecommendationTimestamp,
  //     configureMetaData,
  //     recommendationMetaData,
  //   }) => {
  //     dispatch(
  //       getRecommendationMetadataTemplateAPICall(
  //         { executionId },
  //         ({ response, error }: { response: any; error: boolean }) => {
  //           const { status, data } = response;

  //           if (!error) {
  //             if (status === "failed") {
  //               dispatch(setRecommendationError(true));
  //             } else if (status === "pending") {
  //               if (+new Date() - generateRecommendationTimestamp < 60000) {
  //                 let timer = setTimeout(() => {
  //                   getRecommendationMetDataTemplate({
  //                     executionId,
  //                     generateRecommendationTimestamp,
  //                     configureMetaData,
  //                     recommendationMetaData,
  //                   });
  //                 }, 8000);
  //                 dispatch(setRecommendationTimer(timer));
  //               } else {
  //                 dispatch(setRecommendationError(true));
  //               }
  //             } else {
  //               const formattedData = formateRecommendationData(data);
  //               if (variantList[selectedVariantIndex].id) {
  //                 setUpdateInitialRecommendation(true);
  //               }
  //               dispatch(
  //                 updateRecommendationMetadata({
  //                   data: {
  //                     ...recommendationMetaData,
  //                     ...formattedData,
  //                     isEditing: undefined,
  //                     isDeleting: undefined,
  //                     isVideoImageEdit: undefined,
  //                   },
  //                   configureMetaData,
  //                 })
  //               );
  //             }
  //           } else {
  //             dispatch(setRecommendationError(true));
  //           }
  //         }
  //       )
  //     );
  //   },
  //   []
  // );

  useEffect(() => {
    timerRef.current = recommendationTimer;
  }, [recommendationTimer]);

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        dispatch(setRecommendationTimer(null));
      }
    },
    []
  );

  useEffect(() => {
    if (!recommendationMetaData?.isVideoImageEdit) {
      dispatch({
        type: RESTORE_INITIAL_MEDIA,
        payload: {
          isVideoMediaStudioProcessingFailure: false,
          isImageMediaStudioProcessingFailure: false,
        },
      });
    }
    if (
      recommendationMetaData &&
      Object.keys(recommendationMetaData).length &&
      !recommendationMetaData?.isEditing
    ) {
      if (
        selectedVariantIndex !== undefined &&
        variantList[selectedVariantIndex].id &&
        !updateInitialRecommendation
      ) {
        dispatch(
          updateRecommendationSelectionState({
            primary_text:
              variantList[selectedVariantIndex]?.CreativeAiData?.primaryText
                ?.name,
            headline:
              variantList[selectedVariantIndex]?.CreativeAiData?.headline?.name,
            description:
              variantList[selectedVariantIndex]?.CreativeAiData?.description
                ?.name,
          })
        );
        if (selectedAd?.isMediaStudioAvailable) {
          const index =
            selectedVariantIndex !== undefined ? selectedVariantIndex : 0;
          const variant = variantList[index];

          if (checkAdType(createSpec) === "link_data") {
            dispatch(
              updateRecommendationSelectionState({
                image: recommendationMetaData?.image?.data?.[0]?.id,
              })
            );
            if (variant?.CreativeAiData?.mediaFileId) {
              dispatch({
                type: MEDIA_STUDIO_IMAGE_PROCESSING_LOADER,
                payload: true,
              });
              dispatch(
                getMediaStudioProcessing(
                  {
                    reqId: [variant?.CreativeAiData?.mediaFileId],
                  },
                  (response: GetMediaStudioProcessingReduxType[]) => {
                    dispatch({
                      type: GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
                      payload: response[0],
                    });
                    dispatch({
                      type: MEDIA_STUDIO_IMAGE_PROCESSING_LOADER,
                      payload: false,
                    });
                  }
                )
              );
            } else {
              dispatch({
                type: GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
                payload: {
                  url: undefined,
                  id: undefined,
                },
              });
            }
          } else {
            dispatch(
              updateRecommendationSelectionState({
                video: variantList[selectedVariantIndex]?.payload?.video ?? 0,
              })
            );
            if (variant?.CreativeAiData?.mediaFileId) {
              dispatch({
                type: MEDIA_STUDIO_VIDEO_PROCESSING_LOADER,
                payload: true,
              });
              dispatch(
                getMediaStudioProcessing(
                  {
                    reqId: [variant?.CreativeAiData?.mediaFileId],
                  },
                  (response: GetMediaStudioProcessingReduxType[]) => {
                    dispatch({
                      type: GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO,
                      payload: response[0],
                    });
                    dispatch({
                      type: MEDIA_STUDIO_VIDEO_PROCESSING_LOADER,
                      payload: false,
                    });
                  }
                )
              );
            } else {
              dispatch({
                type: GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO,
                payload: {
                  url: undefined,
                  id: undefined,
                },
              });
            }
          }
        } else {
          dispatch({
            type: GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO,
            payload: {
              url: undefined,
              id: undefined,
            },
          });
          dispatch({
            type: GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
            payload: {
              url: undefined,
              id: undefined,
            },
          });
        }
      } else {
        /// check media studio available
        setUpdateInitialRecommendation(false);
        dispatch(
          updateRecommendationSelectionState({
            primary_text: recommendationMetaData?.primary_text?.data[0]?.text,
            headline: recommendationMetaData?.headline?.data[0]?.text,
            description: recommendationMetaData?.description?.data[0]?.text,
            isChanged: true,
          })
        );
        dispatch({
          type: GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
          payload: {
            url: undefined,
            id: undefined,
          },
        });
        dispatch({
          type: GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO,
          payload: {
            url: undefined,
            id: undefined,
          },
        });

        /// here media studio related logic
        if (selectedAd?.isMediaStudioAvailable) {
          // adType is image
          if (
            checkAdType(createSpec) === "link_data" &&
            recommendationMetaData?.image?.data?.length
          ) {
            dispatch(
              updateRecommendationSelectionState({
                image: recommendationMetaData?.image?.data[0]?.id,
              })
            );
            const variations = imageMediaProcessingPayloadMapping(
              recommendationMetaData?.image?.data[0].data
            );
            dispatch(
              postMediaStudioProcessing({
                adId: selectedAd?.id,
                failureReduxType: "MEDIA_STUDIO_IMAGE_PROCESSING_FAILURE",
                reduxActionType: "MEDIA_STUDIO_IMAGE_PROCESSING_LOADER",
                reduxStoreType: "POST_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE",
                variations,
              })
            );
          } // adType video
          else {
            if (recommendationMetaData?.video?.data?.length) {
              dispatch(
                updateRecommendationSelectionState({
                  video: 0,
                })
              );
              const variations = videoMediaProcessingPayloadMapping(
                recommendationMetaData?.video?.data[0].data
              );
              dispatch(
                postMediaStudioProcessing({
                  adId: selectedAd?.id,
                  failureReduxType: "MEDIA_STUDIO_VIDEO_PROCESSING_FAILURE",
                  reduxActionType: "MEDIA_STUDIO_VIDEO_PROCESSING_LOADER",
                  reduxStoreType: "POST_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO",
                  variations,
                })
              );
            }
          }
        } else {
          dispatch({
            type: GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO,
            payload: {
              url: undefined,
              id: undefined,
            },
          });
          dispatch({
            type: GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE,
            payload: {
              url: undefined,
              id: undefined,
            },
          });
        }
      }
    }
  }, [selectedVariantIndex, recommendationMetaData]);

  // useEffect(() => {
  //   if (reqImageIds.length > 0) {
  //     getVGSProcessingResponse(
  //       {
  //         failureReduxType: "MEDIA_STUDIO_IMAGE_PROCESSING_FAILURE",
  //         reduxActionType: "MEDIA_STUDIO_IMAGE_PROCESSING_LOADER",
  //         storeReduxType: "GET_MEDIA_STUDIO_PROCESSING_REDUX_IMAGE",
  //         timerRedux: "SET_IMAGE_VGS_TIMER",
  //         reqId: reqImageIds,
  //       },
  //       +new Date()
  //     );
  //   }
  // }, [reqImageIds]);

  // useEffect(() => {
  //   if (reqVideoIds.length > 0) {
  //     getVGSProcessingResponse(
  //       {
  //         failureReduxType: "MEDIA_STUDIO_VIDEO_PROCESSING_FAILURE",
  //         reduxActionType: "MEDIA_STUDIO_VIDEO_PROCESSING_LOADER",
  //         storeReduxType: "GET_MEDIA_STUDIO_PROCESSING_REDUX_VIDEO",
  //         timerRedux: "SET_VIDEO_VGS_TIMER",
  //         reqId: reqVideoIds,
  //       },
  //       +new Date()
  //     );
  //   }
  // }, [reqVideoIds]);

  useEffect(() => {
    recommendationRef.current = recommendationMetaData;
  }, [recommendationMetaData]);

  const handleRetryClick = () => {
    setIsGeneratingRecommendation(true);
    dispatch(setRecommendationLoading(true));
    const data = configureMetaData ?? getFormValues(CREATIVE_ELEMENT_AI, true);
    dispatch({
      type: "GENERATE_VARIANT_METADATA",
      payload: {
        user: auth.user,
        data: generateConfigurePayload(
          {
            ...data,
            businessUnit: BUSINESS_UNIT_BY_ID_DATA.businessUnit,
            businessUnitDetail: BUSINESS_UNIT_BY_ID_DATA.businessUnitDetail,
          },
          LEVERS,
          "generate",
          checkAdType(createSpec),
          selectedBrand?.id,
          selectedAd?.id,
          adAccountsSelection?.selectedAdAccountId
        ),
        finalAction: GENERATE_VARIANT_METADATA_STATE_VARIANT,
      },
      callback: () => {
        setIsConfiguring(false);
      },
    });
  };

  useEffect(() => {
    if (checkAdType(createSpec) === "video_data" && getMediaStudioData?.data) {
      /// setting initial state
      for (
        let index = 0;
        index < getMediaStudioData?.data.frames.length;
        index++
      ) {
        dispatch(
          setFrameThumbnailData({
            data: {
              thumbnailUrl: "",
              isThumbnailLoading: true,
            },
            index,
          })
        );
      }

      for (
        let index = 0;
        index < getMediaStudioData?.data.frames.length;
        index++
      ) {
        dispatch(
          getPresignedUrl({
            mediaId: getMediaStudioData?.data?.frames[index]?.previewFile.value,
            type: "preview",
            finalAction: (data: any, error: boolean) => {
              dispatch(
                setFrameThumbnailData({
                  data: {
                    thumbnailUrl: data?.url,
                    isThumbnailLoading: false,
                  },
                  index,
                })
              );
            },
          })
        );
      }
    }
  }, [getMediaStudioData]);

  const formateRecommendationData = (data: any) => {
    const output: any = {};
    Object.keys(data).forEach((item: string) => {
      let generations: Array<any> = [];

      Object.keys(data[item]).forEach((element: any) => {
        if (item === "image") {
          if (data[item][element]?.generations) {
            data[item][element]?.generations.forEach(
              (arr: ImageLayerType[]) => {
                generations = [...generations, { data: arr, id: nanoid() }];
              }
            );
          }
        } else if (item === "video") {
          if (data[item][element]?.generations) {
            const localGenerations = convertVideoFrameData(
              data[item][element]?.generations,
              getMediaStudioData
            );
            generations = [...generations, ...localGenerations];
          }
        } else {
          if (data[item][element]?.generations) {
            generations = [...generations, ...data[item][element]?.generations];
          }
        }
      });

      if (generations.length) {
        output[item] = {
          data: generations,
          selected: item === "image" ? generations[0]?.id : generations[0],
          noOfElementToShow: generations.length > 4 ? 4 : generations.length,
          showMore: generations.length > 4,
        };
      } else if (item === "image" || item === "video") {
        output[item] = {
          data: undefined,
        };
      }
    });
    return output;
  };

  const handleSnackBarClose = () => {
    dispatch(showUpdateAISuccessMsgAction(false));
  };
  const handleToolItemClick = (item: ItemType) => {
    updateSelectedTool(item);
    setIsConfiguring(false);
  };
  const onConfigClick = () => {
    setIsConfiguring(true);
    updateSelectedTool(null);
  };

  return (
    <>
      {((!isImageMediaStudioProcessingFailure &&
        isImageMediaStudioProcessing) ||
        (!isVideoMediaStudioProcessingFailure &&
          isVideoMediaStudioProcessing) ||
        (!frameVGSReqIdFailure && frameVGSReqIdLoading)) && (
        <StyledMediaLoader id="load_editor"></StyledMediaLoader>
      )}
      <StyledDrawer
        id="drawer"
        className="drawer"
        aria-hidden={showEditor ? "false" : "true"}
      >
        <div className="drawer-container">
          {!isGeneratingRecommendation &&
          !addUpdateVariantLoading &&
          selectedVariantIndex !== undefined &&
          !generateRecommendationError ? (
            <div className="drawer-container--body">
              <EditorHeader
                onCrossClick={onCrossClick}
                variantItem={variantList[selectedVariantIndex]}
                mixPanelCreativeStudioOriginalAdDetailsClicked={() => {
                  mixPanelCreativeStudioOriginalAdDetailsClicked(
                    variantList[selectedVariantIndex],
                    selectedTool?.hash
                  );
                }}
              />
              <StyledEditorBody>
                {isConfiguring ? (
                  <Configure
                    variantItem={variantList[selectedVariantIndex]}
                    mixPanelCreativeStudioConfigureClicked={
                      mixPanelCreativeStudioConfigureClicked
                    }
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    reset={reset}
                    watch={watch}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    footerProps={{
                      setIsConfiguring: setIsConfiguring,
                      handleSubmit: handleSubmit,
                      onGenerate: onGenerate,
                      getValues: getValues,
                      isUpdateAISettingChecked: isUpdateAISettingChecked,
                      onUpdateAISettingCheckClick: onUpdateAISettingCheckClick,
                      watch: watch,
                      mixPanelCreativeStudioConfigureGenerateClicked:
                        mixPanelCreativeStudioConfigureGenerateClicked,
                      variantItem: variantList[selectedVariantIndex],
                    }}
                  />
                ) : (
                  <>
                    <Toolbar
                      selected={selectedTool}
                      defaultValue={ToolbarItems[0]}
                      onClick={handleToolItemClick}
                      onConfigClick={onConfigClick}
                    />
                    <Recommendations
                      mixPanelCreativeStudioRenderUpdate={
                        mixPanelCreativeStudioRenderUpdate
                      }
                      mixPanelCreativeStudioAdSettingButtonClick={
                        mixPanelCreativeStudioAdSettingButtonClick
                      }
                      mixPanelCreativeStudioSeeMore={
                        mixPanelCreativeStudioSeeMore
                      }
                      variantItem={variantList[selectedVariantIndex]}
                      textFields={textFields}
                      onAdSettingChange={onAdSettingChange}
                      selectedTool={
                        selectedTool ? selectedTool : ToolbarItems[0]
                      }
                    />
                  </>
                )}
              </StyledEditorBody>
              {isConfiguring ? (
                <ConfigureFooter
                  variantItem={variantList[selectedVariantIndex]}
                  mixPanelCreativeStudioConfigureGenerateClicked={
                    mixPanelCreativeStudioConfigureGenerateClicked
                  }
                  setIsConfiguring={setIsConfiguring}
                  handleSubmit={handleSubmit}
                  onGenerate={onGenerate}
                  getValues={getValues}
                  isUpdateAISettingChecked={isUpdateAISettingChecked}
                  onUpdateAISettingCheckClick={onUpdateAISettingCheckClick}
                  watch={watch}
                />
              ) : (
                <RecommendationFooter
                  setIsConfiguring={setIsConfiguring}
                  textFields={textFields}
                />
              )}
            </div>
          ) : (
            <>
              <div className="drawer-container--close-container">
                <div className="drawer-container--close" onClick={onCrossClick}>
                  <CloseDrawer />
                </div>
              </div>
              <Box
                width="100%"
                height="85vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
                alignContent="center"
              >
                {generateRecommendationError ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <h6>Error while generating Recommendation</h6>
                    <Button
                      sx={{
                        marginTop: "0.625em",
                      }}
                      buttonVariant="contained"
                      onClick={handleRetryClick}
                    >
                      {" "}
                      Tap to retry
                    </Button>
                  </Box>
                ) : (
                  <StyledEditorGifContainer>
                    {/* <CircularProgress thickness={5} /> */}
                    <StyledEditorGif src={EditorGif} alt="" />
                    <StyledGreatBestTxt>
                      Generating Best Recommendations for your Ad
                    </StyledGreatBestTxt>
                    <StyledAISettingTxt>
                      The AI system takes a few seconds to
                    </StyledAISettingTxt>
                    <StyledAIProvideTxt>
                      provide relevant output
                    </StyledAIProvideTxt>
                  </StyledEditorGifContainer>
                )}
              </Box>
            </>
          )}
        </div>
      </StyledDrawer>

      <ConfirmDialog
        show={showDiscardModal}
        onClose={() => setShowDiscardModal(false)}
        title="You have unsaved changes, do you want to discard them?"
        onComplete={onDiscard}
      />
      <AlertDialog
        show={isShowSaveVariantPrompt}
        onClose={() => {
          dispatch(isShowSaveVariantPromptAction(false));
        }}
        title="You need to first save this variant to perform any action."
      />
      <CustomizedSnackBars
        handleClose={handleSnackBarClose}
        open={showUpdateAISuccessMsg && !isGeneratingMetaData}
        msg="Successfully update AI group level setting"
      />
    </>
  );
}
